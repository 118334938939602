import React from 'react'
import { TicketResaleContainer } from 'tf-checkout-react'
import Head from '../../../components/head/index'
import Layout from '../../../components/layout/index'
import './style.scss'
const Ticket = () => {
  return (
    <>
      <Head title='Resale Page' />
      <Layout>
        <TicketResaleContainer />
      </Layout>
    </>
  )
}
export default Ticket
